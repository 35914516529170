import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import './Containers.css';
import backend from './loginRedirectBackend';


function Containers() {

  const [bets, setBets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const response = await backend.getBets();
      if (response.ok) {
        setBets(await response.json());
      }
    })();
  }, [setBets]);

  const betClick = useCallback((betKey) => {
    navigate('/bet/' + betKey);
  }, [navigate]);

  const projectClick = useCallback((projectKey) => {
    navigate('/project/' + projectKey);
  }, [navigate]);

  const teamClick = useCallback((team) => {
    navigate('/teamwork/' + team);
  }, [navigate]);

  return (
    <>
    <h1>Projects</h1>
    {
      [{ key: "CON", name: "ConFusion" }, { key: "SUP", name: "Supersonic" }, { key: "TECH", name: "Tech" }].map(project =>
        <div className="project" key={project.key} onClick={() => projectClick(project.key)}>
          <a href={"https://nordkap.atlassian.net/browse/" + project.key} target="_new" className="key" onClick={e => e.stopPropagation()}>{project.key}</a>
          <span>{project.name}</span>
        </div>
      )
    }

    <h1>Team Work</h1>
    {
      ["ConFusion", "Supersonic"].map(team =>
        <div className="team" key={team} onClick={() => teamClick(team)}>
          <span>Last 2 months: {team}</span>
        </div>
      )
    }

    <h1>Bets</h1>
    {
      bets.map(bet =>
        <div className={"bet " + bet.status.replaceAll(/\s/g, '-')} key={bet.key} onClick={() => betClick(bet.key)}>
          <a href={"https://nordkap.atlassian.net/browse/" + bet.key} target="_new" className="key" onClick={e => e.stopPropagation()}>{bet.key}</a>
          <span>{bet.summary}</span>
        </div>
      )
    }
    </>
  );
}

export default Containers;
