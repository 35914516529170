import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { faCaretLeft as faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import backend from './loginRedirectBackend';
import IssueRow from './IssueRow';
import NewIssue from './NewIssue';
import './EpicContainer.css';

const EPIC_PROJECTS = ["CON", "SUP", "TECH"];
const EXTREME_STATUSES = ["Released", "Won't Do", "Out of Scope"];

function EpicContainer({ loadContainerAndEpics, implementsContainer }) {

  const { containerKey } = useParams();
  const [hideExtremes, setHideExtremes] = useState(true);
  const [epics, setEpics] = useState([]);
  const [container, setContainer] = useState({ key: containerKey });
  const [dragKey, setDragKey] = useState(null);
  const [dragParentKey, setDragParentKey] = useState(null);
  const [dragTargetKey, setDragTargetKey] = useState(null);
  const [dragTargetNewParentKey, setDragTargetNewParentKey] = useState(null);
  const [updateQueueSize, setUpdateQueueSize] = useState(0);
  const [transitionDialogData, setTransitionDialogData] = useState(null);

  useEffect(() => {
    loadContainerAndEpics(containerKey, setContainer, setEpics);
  }, [loadContainerAndEpics, setContainer, setEpics, containerKey]);

  const mutateIssue = useCallback((targetIssueKey, targetParentKey, mutator) => {
    if (!targetParentKey) {
      setEpics((prevEpics) => prevEpics.map((epic) => {
        if (epic.key !== targetIssueKey) {
          return epic;
        }
        return mutator(epic);
      }));
    } else {
      setEpics((prevEpics) => prevEpics.map((epic) => {
        if (epic.key !== targetParentKey) {
          return epic;
        }

        return {
          ...epic,
          stories: epic.stories.map((story) => {
            if (story.key !== targetIssueKey) {
              return story;
            }
            return mutator(story);
          })
        };
      }));
    }
  }, []);

  const loadEpicAndStories = useCallback((epicKey) => {
    backend.getEpicWithStories(epicKey).then(async (epicResponse) => {
      if (epicResponse.ok) {
        const data = await epicResponse.json();
        mutateIssue(data.epic.key, null, (epic) => {
          const refreshedEpic = { ...epic };
          if (!refreshedEpic.edit) {
            refreshedEpic.summary = data.epic.summary;
          }
          refreshedEpic.status = data.epic.status;
          refreshedEpic.rank = data.epic.rank;
          refreshedEpic.stories = data.stories;
          refreshedEpic.loading = false;
          return refreshedEpic;
        });
      }
    });
  }, [mutateIssue]);

  const epicClick = useCallback((clickedEpic) => {
    mutateIssue(clickedEpic.key, null, (epic) => {
      const updatedEpic = { ...epic };
      updatedEpic.expanded = !updatedEpic.expanded;
      if (updatedEpic.expanded) {
        updatedEpic.loading = true;
      }
      return updatedEpic;
    });

    if (!clickedEpic.expanded) {
      loadEpicAndStories(clickedEpic.key);
    }
  }, [mutateIssue, loadEpicAndStories]);

  const summaryClick = useCallback((issue, parentKey) => {
    mutateIssue(issue.key, parentKey, (issue) => ({ ...issue, edit: true }));
  }, [mutateIssue]);

  const summaryChange = useCallback((e, issue, parentKey) => {
    mutateIssue(issue.key, parentKey, (issue) => ({ ...issue, summary: e.target.value }));
  }, [mutateIssue]);

  const summarySave = useCallback((issue, parentKey) => {
    mutateIssue(issue.key, parentKey, (issue) => ({ ...issue, edit: undefined }));
    setUpdateQueueSize((prevSize) => prevSize + 1);
    backend.queueUpdate(issue.key, { summary: issue.summary }).then(() => {
      setUpdateQueueSize((prevSize) => prevSize - 1);
    });
  }, [mutateIssue]);

  const startDrag = useCallback((key, parentKey) => {
    setDragKey(key);
    setDragParentKey(parentKey);
  }, [setDragKey, setDragParentKey]);

  const stopDrag = useCallback(() => {
    if (dragKey) {
      setDragKey(null);
      setDragParentKey(null);
      setDragTargetKey(null);
      setDragTargetNewParentKey(null);
      if (dragTargetKey) {
        const moveInList = (sourceList) => {
          const newIssueList = [...sourceList];
          const [removedIssue] = newIssueList.splice(newIssueList.findIndex(i => i.key === dragKey), 1);
          newIssueList.splice(newIssueList.findIndex(i => i.key === dragTargetKey), 0, removedIssue);
          return newIssueList;
        };
        if (dragParentKey) {
          mutateIssue(dragParentKey, null, (epic) => ({ ...epic, stories: moveInList(epic.stories) }));
        } else {
          setEpics(moveInList);
        }
        
        setUpdateQueueSize((prevSize) => prevSize + 1);
        backend.queueUpdate(dragKey, { rankBefore: dragTargetKey }).then(() => {
          setUpdateQueueSize((prevSize) => prevSize - 1);
        });
      } else if (dragTargetNewParentKey) {
        const dragStory = epics.filter(e => e.key === dragParentKey)[0].stories.filter(s => s.key === dragKey)[0];
        const targetEpic = epics.filter(e => e.key === dragTargetNewParentKey)[0];
        const rankAfterStory = targetEpic.stories.length > 0 ? targetEpic.stories[targetEpic.stories.length-1] : null;

        mutateIssue(dragParentKey, null, (epic) => ({ ...epic, stories: epic.stories.filter(s => s.key !== dragKey) }));
        mutateIssue(dragTargetNewParentKey, null, (epic) => ({ ...epic, stories: [...epic.stories, dragStory], expanded: true, loading: true }));
        
        setUpdateQueueSize((prevSize) => prevSize + 1);
        const updateDef = { parent: dragTargetNewParentKey };
        if (rankAfterStory) {
          updateDef.rankAfter = rankAfterStory.key;
        }
        backend.queueUpdate(dragKey, updateDef).then(() => {
          return loadEpicAndStories(targetEpic.key);
        }).then(() => {
          setUpdateQueueSize((prevSize) => prevSize - 1);
        });
      }
    }
  }, [epics, dragKey, dragParentKey, dragTargetKey, dragTargetNewParentKey, mutateIssue, loadEpicAndStories]);

  const dragEnter = useCallback((targetKey, parentKey) => {
    if (dragKey && targetKey !== dragKey) {
      // Story within same epic, or dragging epic (parentKey == null)
      if (parentKey === dragParentKey) {
        let targetIssue = null;
        if (dragParentKey) {
          const epic = epics.filter(e => e.key === dragParentKey)[0];
          targetIssue = epic.stories.filter(s => s.key === targetKey)[0];
        } else {
          targetIssue = epics.filter(e => e.key === dragKey)[0];
        }
        if (!targetIssue.committed) {
          setDragTargetKey(targetKey);
        }
      }
      // Dragging story to epic that is not the parent epic
      if (dragParentKey && !parentKey && targetKey !== dragParentKey) {
        setDragTargetNewParentKey(targetKey);
      }
    }
  }, [epics, dragKey, dragParentKey]);

  const dragLeave = useCallback(() => {
    setDragTargetKey(null);
    setDragTargetNewParentKey(null);
  }, []);

  const submitNewEpic = useCallback((project, summary, teams, status, labels) => {
    if (summary) {
      setUpdateQueueSize((prevSize) => prevSize + 1);
      backend.queueInsert(project, {
         summary, status, issuetype: 'epic', teams, labels, implements: (implementsContainer ? containerKey : undefined)
      }).then(async (insertResponse) => {
        if (insertResponse.ok) {
          const insertedEpic = (await insertResponse.json());
          setEpics(prevEpics => [...prevEpics, insertedEpic]);
        }
        setUpdateQueueSize((prevSize) => prevSize - 1);
      });
    }
  }, [containerKey, implementsContainer]);

  const submitNewStory = useCallback((project, parentKey, summary, teams, status, labels) => {
    if (summary) {
      setUpdateQueueSize((prevSize) => prevSize + 1);
      backend.queueInsert(project, {
         summary, status, issuetype: 'story', teams, labels, parent: parentKey
      }).then(async (insertResponse) => {
        if (insertResponse.ok) {
          const insertedStory = (await insertResponse.json());
          mutateIssue(parentKey, null, (epic) => ({ ...epic, stories: [...epic.stories, insertedStory] }));
        }
        setUpdateQueueSize((prevSize) => prevSize - 1);
      });
    }
  }, [mutateIssue]);

  const statusClick = useCallback((e, issue, parentKey) => {
    const statusRect = e.target.getBoundingClientRect();
    let coords = null;
    if (statusRect.top > 300) {
      coords = { reverse: false, y: window.innerHeight - statusRect.bottom + 24, x: window.innerWidth - statusRect.right };
    } else {
      coords = { reverse: true, y: statusRect.top + 24, x: window.innerWidth - statusRect.right };
    }
    backend.getTransitions(issue.key).then(async (transitionsResponse) => {;
      if (transitionsResponse.ok) {
        const availableTransitions = await transitionsResponse.json();
        setTransitionDialogData({ issue, parentKey, availableTransitions, coords });
      }
    });
  }, []);

  const transitionStatus = useCallback((issue, parentKey, transition) => {
    mutateIssue(issue.key, parentKey, (mutatedIssue) => ({ ...mutatedIssue, status: transition.name }));
    setUpdateQueueSize((prevSize) => prevSize + 1);
    backend.queueTransition(issue.key, transition.id).then(() => {
      setUpdateQueueSize((prevSize) => prevSize - 1);
    });
  }, [mutateIssue]);

  return (
    <>
    <h1>
      <a href="/" className="back"><FontAwesomeIcon icon={faReply} className="back-icon" /></a>
      <a href={"https://nordkap.atlassian.net/browse/" + container.key} target="_blank" rel="noreferrer" className="key">{container.key}</a>
      <span> {container.name || <ClipLoader className="container-spinner" color='#444' loading="true" size={18} aria-label="Loading Container" />}</span>
      {updateQueueSize > 0 &&
        <span className="save-span">
          <PulseLoader
            className="save-spinner"
            color='#888'
            loading="true"
            size={8}
            aria-label="Updating Issue"
          /> saving
        </span>
      }
    </h1>
    <div className={"epic-container" + (dragKey ? " dragging" : "")} onMouseUp={() => stopDrag()}>
    {
      epics.filter(epic => !(hideExtremes && EXTREME_STATUSES.includes(epic.status))).map(epic =>
        <div key={epic.key}>
          <IssueRow
            issue={epic}
            className="epic"
            parentKey={null}
            dragKey={dragKey}
            dragTargetKey={dragTargetKey}
            dragTargetNewParentKey={dragTargetNewParentKey}
            onStartDrag={startDrag}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onSummaryEdit={summaryClick}
            onSummaryChange={summaryChange}
            onSummarySave={summarySave}
            onRowClick={epicClick}
            onStatusEdit={statusClick}
            showSpinner={epic.loading}>
          </IssueRow>
          { epic.expanded &&
            <div className="story-container">
              { epic.stories.map(story => 
                <IssueRow
                  key={story.key}
                  issue={story}
                  className="story"
                  parentKey={epic.key}
                  dragKey={dragKey}
                  dragTargetKey={dragTargetKey}
                  onStartDrag={startDrag}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onSummaryEdit={summaryClick}
                  onSummaryChange={summaryChange}
                  onSummarySave={summarySave}
                  onStatusEdit={statusClick}>
                </IssueRow>
                )
              }
              <NewIssue
                parentKey={epic.key}
                type="story"
                projects={["TRSR", "ADA", "AC", "NU", "REMI", "CM"]}
                defaultProject="TRSR"
                status="Backlog"
                teams={epic.teams}
                onSubmit={(project, summary, teams, status, labels) => submitNewStory(project, epic.key, summary, teams, status, labels)} />
            </div>
          }
        </div>
      )
    }
      <NewIssue parentKey={containerKey} type="epic" projects={EPIC_PROJECTS} defaultProject={EPIC_PROJECTS.includes(containerKey) ? containerKey : EPIC_PROJECTS[0]} status="Backlog" teams={container.teams} onSubmit={submitNewEpic} />
    </div>
    <input type="checkbox" checked={hideExtremes} id="hideExtremes" onChange={(e) => setHideExtremes(e.target.checked)} /><label htmlFor="hideExtremes">Hide Released and Out-of-Scope epics</label>
    {
      transitionDialogData &&
      <div id="dialog-background" onClick={(e) => { setTransitionDialogData(null); e.stopPropagation(); }}>
        <div
          className="transition-dialog"
          style={transitionDialogData.coords.reverse ? { top: transitionDialogData.coords.y, right: transitionDialogData.coords.x } : { bottom: transitionDialogData.coords.y, right: transitionDialogData.coords.x }}>
          {
            transitionDialogData.availableTransitions.filter(t => t.name !== transitionDialogData.issue.status).map(t => 
              <div key={t.id} className="status" onClick={() => transitionStatus(transitionDialogData.issue, transitionDialogData.parentKey, t)}>{t.name}</div>
            )
          }
        </div>
      </div>
    }
    </>
  );
}

export default EpicContainer;
