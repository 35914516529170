import EpicContainer from "./EpicContainer";
import backend from "./loginRedirectBackend";

const loadProjectAndEpics = async (containerKey, setContainer, setEpics) => {
  const response = await backend.getProjectWithEpics(containerKey);
  if (response.ok) {
    const data = await response.json();
    setEpics(data.epics.map(e => ({expanded: false, ...e })));
    setContainer(data.project);
  }
};

function Project() {
  return <EpicContainer loadContainerAndEpics={loadProjectAndEpics} implementsContainer={false} />
}

export default Project;