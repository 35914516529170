import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PulseLoader from "react-spinners/PulseLoader";
import './TeamWork.css';
import backend from './loginRedirectBackend';

function TeamWork() {

  const { teamName } = useParams();
  const [workRows, setWorkRows] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await backend.getTeamWork(teamName);
      if (response.ok) {
        const workDates = (await response.json());
        const rows = [];
        const currentWork = [];
        // Deliberate off-by-one to handle closing the last issues
        for (let dateIndex = 0; dateIndex <= workDates.length; dateIndex++) {
          const workDate = workDates[dateIndex] || { inProgressIssues: [], testIssues: [] };
          for (let i = 0; i < currentWork.length; i++) {
            if (currentWork[i]) {
              const currentlyInProgress = workDate.inProgressIssues.find(is => is.key === currentWork[i].issue.key);
              const currentlyInTest = workDate.testIssues.find(is => is.key === currentWork[i].issue.key);
              const previousStatus = currentWork[i].status;
              if (previousStatus === "InProgress" && !currentlyInProgress || previousStatus === "InTest" && (!currentlyInTest || currentlyInProgress)) {
                // The current work at index i has ended or has changed status
                if (rows.length <= i) {
                  const padSize = i + 1 - rows.length;
                  for (let pad = 0; pad < padSize; pad++) {
                    rows.push([]);
                  }
                }
                rows[i].push({ ...currentWork[i], lastIndex: dateIndex-1 });
                if (currentlyInProgress) {
                  currentWork[i] = { issue: currentlyInProgress, firstIndex: dateIndex, status: "InProgress" }
                } else if (currentlyInTest) {
                  currentWork[i] = { issue: currentlyInTest, firstIndex: dateIndex, status: "InTest" }
                } else {
                  currentWork[i] = null;
                }
              }
              workDate.inProgressIssues = workDate.inProgressIssues.filter(is => is.key !== currentWork[i]?.issue.key);
              workDate.testIssues = workDate.testIssues.filter(is => is.key !== currentWork[i]?.issue.key);
            }
          }

          for (let issue of workDate.inProgressIssues) {
            let inserted = false;
            for (let i = 0; i < currentWork.length; i++) {
              if (!currentWork[i]) {
                currentWork[i] = { issue, firstIndex: dateIndex, status: "InProgress" };
                inserted = true;
                break;
              }
            }
            if (!inserted) {
              currentWork.push({ issue, firstIndex: dateIndex, status: "InProgress" });
            }
          }

          for (let issue of workDate.testIssues) {
            if (!currentWork.find(w => w && w.issue.key === issue.key)) {
              let inserted = false;
              for (let i = 0; i < currentWork.length; i++) {
                if (!currentWork[i]) {
                  currentWork[i] = { issue, firstIndex: dateIndex, status: "InTest", directToTest: true };
                  inserted = true;
                  break;
                }
              }
              if (!inserted) {
                currentWork.push({ issue, firstIndex: dateIndex, status: "InTest", directToTest: true });
              }
            }
          }
        }
        setWorkRows(rows);
        setDates(workDates.map(wd => ({date: wd.date, dayOfWeek: (new Date(wd.date).getDay())})));
      }
    })();
  }, [setWorkRows, setDates, teamName]);

  return (
    <div className="teamwork">
      {
        dates.length === 0 &&
          <PulseLoader
            className="save-spinner"
            color='#888'
            loading="true"
            size={8}
            aria-label="Updating Issue"
          />
      }
      {
        dates.map((date, dateIndex) =>
          <div key={dateIndex} className={"date weekday" + date.dayOfWeek} style={{ top: 0, left: dateIndex * 50, width: 50 }}>{date.date.split('T')[0].split('-')[2]}</div>
        )
      }
      {
        workRows.map((row, rowIndex) =>
          row.map(work => 
            <div key={rowIndex + "_" + work.firstIndex} title={work.issue.summary} className={
              "work " + work.issue.key.split('-')[0] + " " + work.issue.labels.join(' ') + " " + work.issue.assignee + (work.lastIndex - work.firstIndex > 5 ? " long" : "") + " " + (work.issue.tech ? "tech" : "") + " " + work.status + " " + (work.directToTest ? "directToTest" : "")
              } style={{ top: rowIndex * 40 + 200, left: work.firstIndex * 50, width: (work.lastIndex - work.firstIndex + 1)*50 }}>
              {work.issue.size && `(${work.issue.size})`} <a href={"https://nordkap.atlassian.net/browse/" + work.issue.key}>{work.issue.key} [{work.lastIndex - work.firstIndex + 1}]<br/><span>{work.issue.summary}</span></a>
            </div>
          )
        )
      }
    </div>
  );
}

export default TeamWork;
