import EpicContainer from "./EpicContainer";
import backend from "./loginRedirectBackend";

const loadBetAndEpics = async (containerKey, setContainer, setEpics) => {
  const response = await backend.getBetWithEpics(containerKey);
  if (response.ok) {
    const data = await response.json();
    setEpics(data.epics.map(e => ({expanded: false, ...e })));
    setContainer(data.bet);
  }
};

function Bet() {
  return <EpicContainer loadContainerAndEpics={loadBetAndEpics} implementsContainer={true} />
}

export default Bet;