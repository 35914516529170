import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Containers from './Containers';
import Bet from './Bet';
import Project from './Project';
import TeamWork from './TeamWork';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Containers />} />
        <Route path="/bet/:containerKey" element={<Bet />} />
        <Route path="/project/:containerKey" element={<Project />} />
        <Route path="/teamwork/:teamName" element={<TeamWork />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
