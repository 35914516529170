import { faGripLines, faLock as faHourglassHalf, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClipLoader from "react-spinners/ClipLoader";

function IssueRow({ issue, className, parentKey, dragKey, dragTargetKey, dragTargetNewParentKey, onStartDrag, onDragEnter, onDragLeave, onRowClick, onSummaryEdit, onSummaryChange, onSummarySave, onStatusEdit, showSpinner }) {

  const issueStatusClass = issue.status.replaceAll(/[^\w]/g, '-');

  return (
    <div
      className={className + " " + issueStatusClass + (issue.key === dragKey ? " drag-source" : "") + (issue.key === dragTargetKey ? " drag-target" : "") + (issue.key === dragTargetNewParentKey ? " drag-target-new-parent" : "")}
      onMouseEnter={() => onDragEnter(issue.key, parentKey)}
      onMouseLeave={() => onDragLeave()}
      onClick={() => onRowClick && onRowClick(issue, null)}>
      {
        issue.committed || <div className="grip" onMouseDown={(e) => { onStartDrag(issue.key, parentKey); e.stopPropagation(); e.preventDefault(); }}>
          <FontAwesomeIcon icon={faGripLines} className="grip-icon" />
        </div>
      }
      <div className="status" onContextMenu={(e) => { onStatusEdit(e, issue, parentKey); e.stopPropagation(); e.preventDefault(); }}>{issue.status}</div>
      <a onClick={(e) => e.stopPropagation()} href={"https://nordkap.atlassian.net/browse/" + issue.key} target="_blank" rel="noreferrer" className="key">{issue.key}</a>
      {
        issue.blockedBy.map(blockKey =>
          <a key={blockKey} onClick={(e) => e.stopPropagation()} href={"https://nordkap.atlassian.net/browse/" + blockKey} target="_blank" rel="noreferrer" className="blocked-by" ><FontAwesomeIcon icon={faHourglassHalf} className="grip-icon" /> {blockKey}</a>
        )
      }
      {
        issue.blocking.length > 0 && <span className="blocking" title={issue.blocking.join(', ')}><FontAwesomeIcon icon={faKey} className="grip-icon" /></span>
      }
      {
        issue.edit ?
          <input
            type="text"
            value={issue.summary}
            autoFocus
            onChange={(e) => onSummaryChange(e, issue, parentKey)}
            onBlur={() => onSummarySave(issue, parentKey)}
            onKeyDown={(e) => { if (e.keyCode === 13 || e.keyCode === 27) { onSummarySave(issue, parentKey); } }}
            onClick={(e) => e.stopPropagation()}></input>
          :
          <span
            className="summary"
            onContextMenu={(e) => { onSummaryEdit(issue, parentKey); e.preventDefault() }}>
            {issue.summary}
          </span>
      }
      { showSpinner &&
        <ClipLoader
          className="issue-spinner"
          color='#444'
          loading="true"
          size={8}
          aria-label="Loading Epic"
        />
      }
    </div>
  );
}

export default IssueRow;