const url_prefix = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000/api';

const backend = (loginCallback) => {
  const fetchOrCallLogin = async (...args) => {
    const response = await fetch(...args);
    if (!response.ok && response.status === 401) {
      loginCallback();
    }
    return response;
  };

  return {
    getBets: async () => {
      return await fetchOrCallLogin(`${url_prefix}/bets`);
    },

    getBetWithEpics: async (betKey) => {
      return await fetchOrCallLogin(`${url_prefix}/bets/${betKey}`);
    },

    getProjectWithEpics: async (projectKey) => {
      return await fetchOrCallLogin(`${url_prefix}/projects/${projectKey}`);
    },

    getEpicWithStories: async (epicKey) => {
      return await fetchOrCallLogin(`${url_prefix}/epics/${epicKey}`);
    },

    queueUpdate: async (issueKey, update) => {
      return await fetchOrCallLogin(`${url_prefix}/issues/${issueKey}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(update)
      });
    },

    queueInsert: async (project, issue) => {
      return await fetchOrCallLogin(`${url_prefix}/issues`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project, issue })
      });
    },

    getTransitions: async (issueKey) => {
      return await fetchOrCallLogin(`${url_prefix}/issues/${issueKey}/transitions`);
    },

    queueTransition: async (issueKey, transitionId) => {
      return await fetchOrCallLogin(`${url_prefix}/issues/${issueKey}/transitions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ transitionId })
      });
    },

    getTeamWork: async (teamName) => {
      return await fetchOrCallLogin(`${url_prefix}/teamwork/${teamName}`);
    }
  };
};

export default backend;