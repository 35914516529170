import { useState } from "react";

const tags = ["Roadmap", "Tech"];
const defaultTags = {"Roadmap": true};

function NewIssue({ parentKey, type, projects, defaultProject, teams, status, onSubmit }) {
  const [project, setProject] = useState(defaultProject);
  const [summary, setSummary] = useState("");
  const [labels, setLabels] = useState({...defaultTags});

  return (
    <div className={"new-issue " + type}>
      <div className="status">{status}</div>
      New {type} in: <select value={project} onChange={(e) => setProject(e.target.value)}>
        {
          projects.map(p =>
            <option key={p} value={p}>{p}</option>
          )
        }
      </select>
      {
        tags.map(t =>
          <div className="tag" key={t}>
            <input type="checkbox" id={"tag-" + parentKey + t} checked={labels[t] || false} onChange={(e) => setLabels({ ...labels, [t]: e.target.checked })} />
            <label htmlFor={"tag-" + parentKey + t}>{t}</label>
          </div>
        )
      }
      <input
            type="text"
            value={summary}
            placeholder={"New " + type + " summary..."}
            onChange={(e) => setSummary(e.target.value)}
            onKeyDown={(e) => { if (e.keyCode === 13) { onSubmit(project, summary, teams, status, Object.keys(labels).filter(l => labels[l])); setSummary(""); setLabels({...defaultTags}); } }}
            />
    </div>
  );
}

export default NewIssue;